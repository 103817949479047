@import "components/loader";
@import "components/newsitem";

@import "main/navbar";
@import 'main/members';

.title-container {
  display: flex;
  align-items: center;
  /* Align items vertically in the center */
  justify-content: space-between;
  border-bottom: 1px $focus-green solid;
  padding-bottom: 10px;
  margin-bottom: 1rem;

  /* Align items from the start, icon will be on the left */
  .title {
    font-size: 1.5rem;
    font-weight: bold;
  }

  &.sub-title {
    padding-bottom: 5px;

    .title {
      font-size: 1rem;
      font-weight: bold;
    }
  }
}
