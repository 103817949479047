.nav-link {
  .svg-inline--fa {
    width: 1em;
  }
}

.btn-secondary,
.p-button {
  color: #fff;
  background-color: $nav-dark;
  border: $nav-dark;

  &:hover {
    background-color: $focus-green;
    border-color: $focus-green;
    color: #fff;
  }
}


.navbar-toggler {
  border: 0;

  &:hover {
    @include navigation-focus();
  }
}

.p-accordion-header-link,
.p-tabview-nav-link {
  color: #000 !important;
  text-decoration: none;

  &:hover {
    color: $focus-green;
  }
}

.p-dropdown-items-wrapper {
  .p-dropdown-items {
    padding-left: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include text();
}

.p-carousel-indicators {
  padding: 0 !important;

}

.p-carousel-indicator {

  .p-link {
    background-color: $focus-green;
  }
}

.p-carousel-next,
.p-carousel-prev {
  background-color: transparent;
}

a {
  color: $focus-green !important;
}

#topbar{
  a{
    color: #000 !important;
  }
}

.navbar-nav {
  a {
    color: #fff !important;
  }
}
