.site-nav {
  background-color: $nav-dark;
  color: #fff;

  .navbar-toggler {
    color: #fff;
  }

  a.nav-link {
    color: #fff;
    text-decoration: none;

    &:hover,
    &.active {
      @include navigation-focus();
    }
  }

  #filter-nav,
  #user-nav {
    .dropdown-menu {
      background-color: $nav-dark;
    }

    .dropdown-item {

      &:hover,
      &.active {
        @include navigation-focus-dropdown();
        color: $menu-highlight-color !important;
      }
    }

    a.nav-link,
    span {
      color: #fff;
      text-decoration: none;

      &:hover,
      &.active {
        @include navigation-focus-dropdown();
        color: $menu-highlight-color !important;
      }
    }
  }

  .offcanvas {
    background-color: $nav-dark !important;

    .menu-title {
      display: none;
      color: #fff;
      border-bottom: 1px solid #fff;
    }

    .offcanvas-close {
      display: none;
      float: right;
      color: #fff;
    }

    &.show {
      .offcanvas-close {
        display: block;
      }

      .menu-title {
        display: block;
      }
    }
  }
}
